import React from 'react';

import Layout from '../components/layout';
import { useAuthContext } from '../components/authContext';
import FooterButton from '../components/footerButton';
import { BaseProps } from '../typings/types';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import FormHeader from '../components/formHeader';
import { Grid, Typography } from '@material-ui/core';

interface PureIndexPageProps {
  login: () => void;
  loggingIn: boolean;
}

export const PureIndexPage: React.FC<PureIndexPageProps> = ({
  login,
  loggingIn,
}) =>
  loggingIn ? (
    <Layout navigateBackToPage={null} showUsername={true}>
      <Grid alignContent="flex-start" justify="space-between" container>
        <Grid container justify="space-between" direction="row">
          <FormHeader>Logging in...</FormHeader>
        </Grid>
      </Grid>
    </Layout>
  ) : (
    <Layout>
      <Typography variant="h6">YOU ARE LOGGED OUT</Typography>
      <FooterButton
        color="secondary"
        onClick={(): void => {
          login();
        }}
      >
        LOGIN
      </FooterButton>
    </Layout>
  );

const IndexPage: React.FC<BaseProps> = ({ location: { pathname } }) => {
  useAuthRouteProtection(pathname);
  const { login, loggingIn, loggedIn } = useAuthContext();
  return <PureIndexPage login={login} loggingIn={loggingIn || loggedIn} />;
};

export default IndexPage;
